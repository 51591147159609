export default {
  title: `
    De Débutant à Expert <br />
    Programmez avec Maîtrise en Toute Simplicité
  `,
  description: `
    SkillSynergy propose des cours en direct pour tous les niveaux.
    <br /> Apprenez la programmation et l'ingénierie logicielle étape par étape et développez votre confiance en vos compétences dès aujourd'hui !
  `,
  meeting: "Réservez une Consultation Gratuite",
  follow: "Suivez-nous",
};
