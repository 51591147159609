export default {
  title: `
    Vom Anfänger zum Profi <br />
    Programmieren leicht gemacht
  `,
  description: `
    SkillSynergy bietet Live-Kurse für alle Levels an.
    <br /> Lernen Sie Programmierung und Softwareentwicklung Schritt für Schritt und bauen Sie noch heute Vertrauen in Ihre Fähigkeiten auf!
  `,
  meeting: "Kostenlose Beratung reservieren",
  follow: "Folgen Sie uns",
};
