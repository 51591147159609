import { Link } from "@remix-run/react";

import { useLanguage } from "~/context/LanguageContext";
import { getLocalizedPagePath } from "~/helpers/navigationHelper";

import i18n from "./i18n";

export default function ProvidedCourses({ className }: { className?: string }) {
  const { lang } = useLanguage();
  const t = i18n[lang];

  return (
    <section
      className={"cs-shape_wrap_4 cs-parallax mt-5 mb-5 pt-5 pb-5 " + className}
    >
      <div className="cs-shape_4 cs-to_up"></div>
      <div className="cs-shape_4 cs-to_right"></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-4">
            <div className="cs-section_heading cs-style1">
              <h3 className="cs-section_subtitle">{t.services}</h3>
              <h2 className="cs-section_title">{t.skills}</h2>
            </div>
            <div className="cs-height_90 cs-height_lg_45"></div>
          </div>
          <div className="col-xl-8">
            <div className="row">
              <div className="col-lg-3 col-sm-6 cs-hidden_mobile"></div>
              <div className="col-lg-3 col-sm-6">
                <div className="cs-hobble">
                  <Link
                    to={getLocalizedPagePath("/course/algorithms")}
                    className="cs-card cs-style1 cs-hover_layer1"
                  >
                    <img
                      loading="lazy"
                      src="/img/service/service_1.webp"
                      alt="Service"
                    />
                    <div className="cs-card_overlay"></div>
                    <div className="cs-card_info">
                      <span className=" cs-hover_layer3 cs-accent_bg"></span>
                      <h2 className="cs-card_title">{t.algorithms}</h2>
                    </div>
                  </Link>
                </div>
                <div className="cs-height_0 cs-height_lg_30"></div>
              </div>
              <div className="col-lg-3 col-sm-6 cs-hidden_mobile"></div>
              <div className="col-lg-3 col-sm-6">
                <div className="cs-hobble">
                  <Link
                    to={getLocalizedPagePath("/course/programming")}
                    className="cs-card cs-style1 cs-hover_layer1"
                  >
                    <img
                      loading="lazy"
                      src="/img/service/service_2.webp"
                      alt="Service"
                    />
                    <div className="cs-card_overlay"></div>
                    <div className="cs-card_info">
                      <span className=" cs-hover_layer3 cs-accent_bg"></span>
                      <h2 className="cs-card_title">{t.programming}</h2>
                    </div>
                  </Link>
                </div>
                <div className="cs-height_0 cs-height_lg_30"></div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="cs-hobble">
                  <Link
                    to={getLocalizedPagePath("/course/architecture")}
                    className="cs-card cs-style1 cs-hover_layer1"
                  >
                    <img
                      loading="lazy"
                      src="/img/service/service_3.webp"
                      alt="Service"
                    />
                    <div className="cs-card_overlay"></div>
                    <div className="cs-card_info">
                      <span className=" cs-hover_layer3 cs-accent_bg"></span>
                      <h2 className="cs-card_title">{t.architecture}</h2>
                    </div>
                  </Link>
                </div>
                <div className="cs-height_0 cs-height_lg_30"></div>
              </div>
              <div className="col-lg-3 col-sm-6 cs-hidden_mobile"></div>
              <div className="col-lg-3 col-sm-6">
                <div className="cs-hobble">
                  <Link
                    to={getLocalizedPagePath("/course/web")}
                    className="cs-card cs-style1 cs-hover_layer1"
                  >
                    <img
                      loading="lazy"
                      src="/img/service/service_4.webp"
                      alt="Service"
                    />
                    <div className="cs-card_overlay"></div>
                    <div className="cs-card_info">
                      <span className=" cs-hover_layer3 cs-accent_bg"></span>
                      <h2 className="cs-card_title">{t.web}</h2>
                    </div>
                  </Link>
                </div>
                <div className="cs-height_0 cs-height_lg_30"></div>
              </div>
              <div className="col-lg-3 col-sm-6 cs-hidden_mobile"></div>
              <div className="col-lg-3 col-sm-6 cs-hidden_mobile"></div>
              <div className="col-lg-3 col-sm-6">
                <div className="cs-hobble">
                  <Link
                    to={getLocalizedPagePath("/course/mobile")}
                    className="cs-card cs-style1 cs-hover_layer1"
                  >
                    <img
                      loading="lazy"
                      src="/img/service/service_5.webp"
                      alt="Service"
                    />
                    <div className="cs-card_overlay"></div>
                    <div className="cs-card_info">
                      <span className=" cs-hover_layer3 cs-accent_bg"></span>
                      <h2 className="cs-card_title">{t.mobile}</h2>
                    </div>
                  </Link>
                </div>
                <div className="cs-height_0 cs-height_lg_30"></div>
              </div>
              <div className="col-lg-3 col-sm-6 cs-hidden_mobile"></div>
              <div className="col-lg-3 col-sm-6">
                <div className="cs-hobble">
                  <Link
                    to={getLocalizedPagePath("/course/server")}
                    className="cs-card cs-style1 cs-hover_layer1"
                  >
                    <img
                      loading="lazy"
                      src="/img/service/service_6.webp"
                      alt="Service"
                    />
                    <div className="cs-card_overlay"></div>
                    <div className="cs-card_info">
                      <span className=" cs-hover_layer3 cs-accent_bg"></span>
                      <h2 className="cs-card_title">{t.server}</h2>
                    </div>
                  </Link>
                </div>
                <div className="cs-height_0 cs-height_lg_30"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
