export default {
  services: "ما يمكننا تقديمه",
  skills: "المهارات التي ستتقنها",
  algorithms: "الخوارزميات",
  programming: "برمجة أساسية",
  architecture: "هندسة البرمجيات",
  web: "تطوير الويب",
  mobile: "تطوير التطبيقات المحمولة",
  server: "تطوير قواعد البيانات والخوادم",
};
