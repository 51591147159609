export default {
  process: "عملية التعلم لدينا",
  approach: "نهج مخصص لتحقيق الإتقان",
  assessment: {
    title: "التقييم",
    description: `نقوم بتقييم مهاراتك الحالية وتخصيص محتوى الدورة ليتناسب مع
                  أهدافك، سواء كنت مبتدئًا أو متعلمًا متقدمًا.`,
  },
  learning: {
    title: "التعلم",
    description: `شارك في دورات مباشرة وتفاعلية مع إرشادات في الوقت الفعلي
                  ومشاريع عملية تعكس تحديات العالم الحقيقي.`,
  },
  mastery: {
    title: "الإتقان",
    description: `احصل على تعليقات ودعم مستمرين لتحسين مهاراتك وزيادة ثقتك
                  في تطبيق معرفتك في مواقف الحياة الواقعية.`,
  },
  choosing: "لماذا تختارنا؟",
  expert: {
    title: "مدربون خبراء",
    description: `يتكون فريقنا من محترفين ذوي خبرة عالية في البرمجة وهندسة البرمجيات،
                  مكرسين لمساعدتك على النجاح. تميزنا خبرتهم الواقعية ومعرفتهم بالصناعة عن
                  المنافسين.`,
  },
  tailored: {
    title: "تعلم مخصص",
    description: `نقوم بتخصيص دوراتنا لتتناسب مع أهدافك، مما يضمن حصولك على
                  تدريب حديث وملائم. سواء كنت تبدأ الآن أو تسعى لتطوير مهاراتك، فإننا
                  نتكيف مع احتياجاتك.`,
  },
  proven: {
    title: "نتائج مثبتة",
    description: `مع سنوات من الخبرة، ساعدنا العديد من المتعلمين على تحقيق
                  أهدافهم في البرمجة وهندسة البرمجيات. يضمن نهجنا العملي أنك
                  جاهز لتطبيق مهاراتك على الفور.`,
  },
  learn: "تعرف على المزيد",
};
