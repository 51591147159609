import { SEOHandle } from "@nasa-gcn/remix-seo";

import CallToAction from "~/components/callToAction/CallToAction";
import ContactForm from "~/components/contactForm/ContactForm";
import Pricing from "~/components/pricing/Pricing";
import ProvidedCourses from "~/components/providedCourses/ProvidedCourses";
import generateSitemapEntriesFor from "~/helpers/sitemapEntriesWithLocal";
import { rootMeta } from "~/root.meta";
import About from "~/routes/($locale)._index/components/about/About";
import Hero from "~/routes/($locale)._index/components/hero/Hero";
import Questions from "~/routes/($locale)._index/components/questions/Questions";

import IntroVideo from "./components/introVideo/IntroVideo";

export function meta() {
  return [{ title: "SkillSynergy" }, ...rootMeta];
}

export const handle: SEOHandle = generateSitemapEntriesFor("/");

export default function Index() {
  return (
    <>
      <Hero />
      <IntroVideo />
      <About></About>
      <ProvidedCourses className="cs-gradient_1" />
      <Pricing />
      <CallToAction fullWidth={true} backgroundImage="/img/cta/cta_bg_5.webp" />
      <Questions />
      <ContactForm />
    </>
  );
}
