export default {
  title: `
    من مبتدئ إلى محترف <br />
    أتقن البرمجة بسهولة
  `,
  description: `
    تقدم SkillSynergy دورات مباشرة لجميع المستويات.
    <br /> تعلم البرمجة والهندسة البرمجية خطوة بخطوة وزد من ثقتك في مهاراتك اليوم!
  `,
  meeting: "احجز استشارة مجانية",
  follow: "تابعنا",
};
