export default {
  process: "Our Learning Process",
  approach: "A tailored approach to mastery",
  assessment: {
    title: "Assessment",
    description: `We evaluate your current skills and tailor the course content to
                meet your goals, from beginners to advanced learners.`,
  },
  learning: {
    title: "Learning",
    description: `Engage in live, hands-on courses with real-time guidance and
                practical projects that reflect real-world challenges.`,
  },
  mastery: {
    title: "Mastery",
    description: `Receive ongoing feedback and support to refine your skills and
                build confidence for applying your knowledge in real-world
                scenarios.`,
  },
  choosing: "Why Choose Us?",
  expert: {
    title: "Expert Instructors",
    description: `Our team consists of highly experienced professionals in
                programming and software engineering, dedicated to helping you
                succeed. Their real-world expertise and industry knowledge set
                us apart from the competition.`,
  },
  tailored: {
    title: "Tailored Learning",
    description: `We personalize our courses to match your goals, ensuring you
                receive the most relevant, up-to-date training. Whether you're
                just starting or advancing your skills, we adapt to your
                needs.`,
  },
  proven: {
    title: "Proven Results",
    description: `With years of experience, we've helped countless learners
                achieve their programming and software engineering goals. Our
                practical, hands-on approach ensures you’re ready to apply
                your skills immediately.`,
  },
  learn: "Learn More",
};
