export default {
  services: "Ce Que Nous Pouvons Faire",
  skills: "Compétences Que Vous Allez Maîtriser",
  algorithms: "Algorithmes",
  programming: "Programmation Fondamentale",
  architecture: "Architecture Logicielle",
  web: "Développement Web",
  mobile: "Développement Mobile",
  server: "Bases de Données et Serveurs",
};
