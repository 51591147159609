import { useState } from "react";

import { INTRODUCTION_VIDEO_URL } from "~/config/constants";

import "./IntroVideo.scss";

export default function IntroVideo() {
  const [showModal, setShowModal] = useState(false);

  const openVideoModal = (event: React.MouseEvent) => {
    event.preventDefault();
    setShowModal(true);
  };

  const closeVideoModal = (event: React.MouseEvent) => {
    event.preventDefault();
    setShowModal(false);
    const html = document.querySelector("html") as HTMLElement;
    html.classList.remove("overflow-hidden");
  };

  return (
    <div className="course-intro-video cs-video_block_1_wrap">
      <div className="container">
        <a
          href="https://youtu.be/ens-KF0NxkQ"
          className="cs-video_block cs-style1 cs-video_open cs-bg"
          style={{ backgroundImage: "url(/img/video_bg.webp)" }}
          onClick={openVideoModal}
        >
          <span className="cs-player_btn cs-accent_color">
            <span></span>
          </span>
        </a>
      </div>

      <div className={"cs-video_popup " + (showModal ? "active" : "")}>
        <div className="cs-video_popup_overlay"></div>
        <div className="cs-video_popup_content">
          <div
            className="cs-video_popup_layer"
            onClick={closeVideoModal}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
          ></div>
          <div className="cs-video_popup_container">
            <div className="cs-video_popup_align">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src={showModal ? INTRODUCTION_VIDEO_URL : "about:blank"}
                  title="Introduction Youtube Video"
                ></iframe>
              </div>
            </div>
            <div
              className="cs-video_popup_close"
              onClick={closeVideoModal}
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
