export default {
  services: "Was Wir Tun Können",
  skills: "Fähigkeiten, Die Sie Meistern Werden",
  algorithms: "Algorithmen",
  programming: "Grundlagen der Programmierung",
  architecture: "Softwarearchitektur",
  web: "Webentwicklung",
  mobile: "Mobile Entwicklung",
  server: "Datenbank- und Serverentwicklung",
};
