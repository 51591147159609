export default {
  services: "What Can We Do",
  skills: "Skills You'll Master",
  algorithms: "Algorithms",
  programming: "Fundamental programming",
  architecture: "Software Design & Architecture",
  web: "Web development",
  mobile: "Mobile development",
  server: "Database and Server development",
};
