import { Link } from "@remix-run/react";

import { useLanguage } from "~/context/LanguageContext";
import { getLocalizedPagePath } from "~/helpers/navigationHelper";

import i18n from "./i18n";

export default function About() {
  const { lang } = useLanguage();
  const t = i18n[lang];

  return (
    <>
      <div className="container mt-5">
        <div className="cs-section_heading cs-style1 text-center">
          <h3 className="cs-section_subtitle">{t.process}</h3>
          <h2 className="cs-section_title">{t.approach}</h2>
        </div>
        <div className="cs-height_90 cs-height_lg_45"></div>
        <div className="row">
          <div className="col-lg-4">
            <div className="cs-iconbox cs-style2 text-center">
              <div className="cs-iconbox_icon">
                <svg
                  width="58"
                  height="57"
                  viewBox="0 0 58 57"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.75"
                    y="0.75"
                    width="48.5"
                    height="48.5"
                    rx="4.25"
                    stroke="#999696"
                    strokeWidth="1.5"
                    strokeDasharray="3 3"
                  />
                  <rect
                    x="8"
                    y="7"
                    width="50"
                    height="50"
                    rx="5"
                    fill="#03A9F4"
                  />
                </svg>
              </div>
              <h2 className="cs-iconbox_title">{t.assessment.title}</h2>
              <div className="cs-iconbox_subtitle">
                {t.assessment.description}
              </div>
            </div>
            <div className="cs-height_30 cs-height_lg_35"></div>
          </div>
          <div className="col-lg-4">
            <div className="cs-iconbox cs-style2 text-center">
              <div className="cs-iconbox_icon">
                <svg
                  width="58"
                  height="57"
                  viewBox="0 0 58 57"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="25"
                    cy="25"
                    r="24.25"
                    stroke="#999696"
                    strokeWidth="1.5"
                    strokeDasharray="3 3"
                  />
                  <circle cx="33" cy="32" r="25" fill="#03A9F4" />
                </svg>
              </div>
              <h2 className="cs-iconbox_title">{t.learning.title}</h2>
              <div className="cs-iconbox_subtitle">
                {t.learning.description}
              </div>
            </div>
            <div className="cs-height_30 cs-height_lg_35"></div>
          </div>
          <div className="col-lg-4">
            <div className="cs-iconbox cs-style2 text-center">
              <div className="cs-iconbox_icon">
                <svg
                  width="57"
                  height="54"
                  viewBox="0 0 57 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.6803 4.01786L24.2268 1.33929L24.8763 1.71429L25 1.5L25.1237 1.71429L25.7732 1.33929L27.3197 4.01786L26.6702 4.39286L28.2167 7.07143L28.8662 6.69643L30.4127 9.375L29.7631 9.75L31.3096 12.4286L31.9591 12.0536L33.5056 14.7321L32.8561 15.1071L34.4026 17.7857L35.0521 17.4107L36.5986 20.0893L35.949 20.4643L37.4955 23.1429L38.145 22.7679L39.6915 25.4464L39.042 25.8214L40.5885 28.5L41.238 28.125L42.7845 30.8036L42.1349 31.1786L43.6814 33.8571L44.3309 33.4821L45.8774 36.1607L45.2279 36.5357L45.3516 36.75H45.1042V37.5H42.0112V36.75H38.9183V37.5H35.8253V36.75H32.7324V37.5H29.6394V36.75H26.5465V37.5H23.4535V36.75H20.3606V37.5H17.2676V36.75H14.1747V37.5H11.0817V36.75H7.98879V37.5H4.89584V36.75H4.6484L4.77212 36.5357L4.1226 36.1607L5.66908 33.4821L6.3186 33.8571L7.86507 31.1786L7.21555 30.8036L8.76202 28.125L9.41154 28.5L10.958 25.8214L10.3085 25.4464L11.855 22.7679L12.5045 23.1429L14.051 20.4643L13.4014 20.0893L14.9479 17.4107L15.5974 17.7857L17.1439 15.1071L16.4944 14.7321L18.0409 12.0536L18.6904 12.4286L20.2369 9.75L19.5873 9.375L21.1338 6.69643L21.7833 7.07143L23.3298 4.39286L22.6803 4.01786Z"
                    stroke="#999696"
                    strokeWidth="1.5"
                    strokeDasharray="3 3"
                  />
                  <path d="M32 4L53.6506 41.5H10.3494L32 4Z" fill="#03A9F4" />
                </svg>
              </div>
              <h2 className="cs-iconbox_title">{t.mastery.title}</h2>
              <div className="cs-iconbox_subtitle">{t.mastery.description}</div>
            </div>
            <div className="cs-height_30 cs-height_lg_35"></div>
          </div>
        </div>
      </div>
      <section>
        <div className="cs-height_110 cs-height_lg_40"></div>
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-lg-5">
              <img
                src="/img/about/about_img_9.webp"
                alt="About"
                className="w-100 cs-radius_5"
              />
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className="cs-height_0 cs-height_lg_40"></div>
              <div className="cs-section_heading cs-style1">
                <h3 className="cs-section_subtitle">{t.choosing}</h3>
                <h2 className="cs-section_title">{t.expert.title}</h2>
                <div className="cs-height_30 cs-height_lg_20"></div>
                <p>{t.expert.description}</p>

                <h2 className="cs-section_title">{t.tailored.title}</h2>
                <div className="cs-height_30 cs-height_lg_20"></div>
                <p>{t.tailored.description}</p>

                <h2 className="cs-section_title">{t.proven.title}</h2>
                <div className="cs-height_30 cs-height_lg_20"></div>
                <p>{t.proven.description}</p>

                <div className="cs-height_45 cs-height_lg_20"></div>
                <Link
                  to={getLocalizedPagePath("/about")}
                  className="cs-text_btn wow fadeInLeft"
                  data-wow-duration="0.8s"
                  data-wow-delay="0.2s"
                >
                  <span>{t.learn}</span>
                  <svg
                    width="26"
                    height="12"
                    viewBox="0 0 26 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.5303 6.53033C25.8232 6.23744 25.8232 5.76256 25.5303 5.46967L20.7574 0.696699C20.4645 0.403806 19.9896 0.403806 19.6967 0.696699C19.4038 0.989593 19.4038 1.46447 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM0 6.75H25V5.25H0V6.75Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="cs-height_150 cs-height_lg_80"></div>
      </section>
    </>
  );
}
