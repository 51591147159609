import parse from "html-react-parser";

import { LINKED_IN_URL, YOUTUBE_URL } from "~/config/constants";
import { useLanguage } from "~/context/LanguageContext";

import i18n from "./i18n";

export default function Hero() {
  const { lang } = useLanguage();
  const t = i18n[lang];

  return (
    <div
      className="cs-hero cs-style3 cs-bg cs-fixed_bg cs-shape_wrap_1"
      id="home"
    >
      <div className="cs-shape_1"></div>
      <div className="cs-shape_1"></div>
      <div className="cs-shape_1"></div>
      <div className="cs-circle_1"></div>
      <div className="cs-circle_2"></div>
      <div className="container">
        <div className="cs-hero_text">
          <h1
            className="cs-hero_title text-end wow fadeInRight animated"
            data-wow-duration="0.8s"
            data-wow-delay="0.2s"
          >
            {parse(t.title)}
          </h1>
          <div className="cs-hero_subtitle text-end">
            {parse(t.description)}
          </div>
        </div>
      </div>
      <div className="cs-hero_social_wrap cs-left_side cs-primary_font cs-primary_color">
        <div className="cs-hero_social_title">{t.follow}</div>
        <ul className="cs-hero_social_links">
          <li>
            <a href={LINKED_IN_URL} target="_blank" rel="noreferrer">
              LinkedIn
            </a>
          </li>
          <li>
            <a href={YOUTUBE_URL} target="_blank" rel="noreferrer">
              Youtube
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
