export default {
  process: "Notre Processus d'Apprentissage",
  approach: "Une approche personnalisée pour maîtriser",
  assessment: {
    title: "Évaluation",
    description: `Nous évaluons vos compétences actuelles et adaptons le contenu du cours
                  à vos objectifs, que vous soyez débutant ou apprenant avancé.`,
  },
  learning: {
    title: "Apprentissage",
    description: `Participez à des cours en direct et interactifs avec des conseils en temps réel
                  et des projets pratiques qui reflètent les défis du monde réel.`,
  },
  mastery: {
    title: "Maîtrise",
    description: `Recevez des retours et un soutien continus pour affiner vos compétences et
                  renforcer votre confiance dans l'application de vos connaissances à des
                  situations réelles.`,
  },
  choosing: "Pourquoi Nous Choisir ?",
  expert: {
    title: "Instructeurs Experts",
    description: `Notre équipe est composée de professionnels hautement expérimentés en
                  programmation et ingénierie logicielle, dédiés à votre succès. Leur expertise
                  et connaissance de l'industrie nous distinguent de la concurrence.`,
  },
  tailored: {
    title: "Apprentissage Personnalisé",
    description: `Nous personnalisons nos cours pour correspondre à vos objectifs, garantissant
                  une formation pertinente et à jour. Que vous débutiez ou perfectionniez vos
                  compétences, nous nous adaptons à vos besoins.`,
  },
  proven: {
    title: "Résultats Prouvés",
    description: `Avec des années d'expérience, nous avons aidé de nombreux apprenants à atteindre
                  leurs objectifs en programmation et ingénierie logicielle. Notre approche
                  pratique et concrète vous assure d'être prêt à appliquer vos compétences
                  immédiatement.`,
  },
  learn: "En Savoir Plus",
};
