export default {
  process: "Unser Lernprozess",
  approach: "Ein maßgeschneiderter Ansatz zur Meisterung",
  assessment: {
    title: "Bewertung",
    description: `Wir bewerten Ihre aktuellen Fähigkeiten und passen den Kursinhalt an
                  Ihre Ziele an, egal ob Sie Anfänger oder Fortgeschrittener sind.`,
  },
  learning: {
    title: "Lernen",
    description: `Nehmen Sie an Live-Kursen mit praktischen Projekten und Echtzeit-Anleitung teil,
                  die reale Herausforderungen widerspiegeln.`,
  },
  mastery: {
    title: "Meisterschaft",
    description: `Erhalten Sie kontinuierliches Feedback und Unterstützung, um Ihre Fähigkeiten
                  zu verfeinern und Selbstvertrauen beim Anwenden Ihres Wissens in realen
                  Situationen zu gewinnen.`,
  },
  choosing: "Warum Uns Wählen?",
  expert: {
    title: "Erfahrene Dozenten",
    description: `Unser Team besteht aus hochqualifizierten Fachleuten im Bereich
                  Programmierung und Softwareentwicklung, die sich Ihrem Erfolg verschrieben
                  haben. Ihre Praxiserfahrung und Branchenkenntnisse heben uns von der
                  Konkurrenz ab.`,
  },
  tailored: {
    title: "Individuelles Lernen",
    description: `Wir passen unsere Kurse an Ihre Ziele an, damit Sie die aktuellste und
                  relevanteste Ausbildung erhalten. Egal ob Anfänger oder Fortgeschrittener, wir
                  richten uns nach Ihren Bedürfnissen.`,
  },
  proven: {
    title: "Bewährte Ergebnisse",
    description: `Mit jahrelanger Erfahrung haben wir unzähligen Lernenden geholfen, ihre
                  Ziele in Programmierung und Softwareentwicklung zu erreichen. Unser
                  praxisorientierter Ansatz stellt sicher, dass Sie Ihre Fähigkeiten sofort
                  anwenden können.`,
  },
  learn: "Mehr Erfahren",
};
