export default {
  title: `
    From Beginner to Pro <br />
    Master coding with Ease
  `,
  description: `
    SkillSynergy offers live courses for all levels.
    <br /> Learn programming and software engineering step-by-step and build confidence in your
    skills today!
  `,
  meeting: "Schedule a Free Consultation",
  follow: "Follow Us",
};
